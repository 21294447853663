<template>
  <div class="supplyDemand-join">
    <div class="panel">
      <div class="box flex-2 box-1" style="margin-right: 4px;">
        <img :src="getImg('bg_1.png')" class="bg">
        <div class="row-1">
          <div class="theme">
            <b class="title">供需通</b>医械后市场采销协同开放平台
          </div>
          <div class="desc">打造医械后市场产业上下游企业采销对接开放平台，依托平台丰富的医疗设备备件库及海量优质供应商，支持医疗设备、备件、维修、保养、计量、检测采销全流程数字化管理，助力采购方与供应商的高效协同，推动医械后市场行业阳光采购的实现。</div>
        </div>
        <div class="row-2">
          <div class="advantage">
            <div class="advantage-title">优势一：行业方案</div>
            <div class="advantage-remark">深耕医械后市场，提供行业<br/>解决方案，服务占比超<b class="bold">50%</b></div>
          </div>
          <div class="advantage">
            <div class="advantage-title">优势二：备件库</div>
            <div class="advantage-remark">丰富的备件库资源，<br/>覆盖<b class="bold">4W+</b>备件型号</div>
          </div>
          <div class="advantage">
            <div class="advantage-title">优势三：供应商</div>
            <div class="advantage-remark">海量优质供应商，<br/><b class="bold">8K+</b>上下游企业/医疗机构</div>
          </div>
        </div>
      </div>
      <div class="box flex-1 box-2" style="margin-left: 4px;">
        <img :src="getImg('bg_2.png')" class="bg">
        <div class="audience-box">
          <div class="topic">产品受众</div>
          <div class="audience audience-1">
            <div class="audience-title">医械管理端</div>
            <div class="audience-remark">国内专业的医学装备资产管理服务商，<br/>医疗机构、高校实验室、医疗器械厂商和经销商</div>
          </div>
          <div class="audience audience-2">
            <div class="audience-title">小型三方服务商</div>
            <div class="audience-remark">想要增加客户<br/>来源的优质服务商</div>
          </div>
          <div class="audience audience-3">
            <div class="audience-title">优质工程师</div>
            <div class="audience-remark">医工群体</div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel">
      <div class="box flex-1 box-3" style="margin-right: 4px;">
        <img :src="getImg('bg_3.png')" class="bg">
        <div class="title-box">智能寻源/商机推荐</div>
        <div class="line-box intro">
          <div class="line">
            <img :src="getImg('ic_select.svg')" class="icon">
            <span>一键分发多个服务商/工程师报价</span>
          </div>
          <div class="line">
            <img :src="getImg('ic_select.svg')" class="icon">
            <span>服务商/工程师实时在线接单</span>
          </div>
        </div>
      </div>
      <div class="box flex-1 box-4" style="margin-left: 4px;">
        <img :src="getImg('bg_4.png')" class="bg">
        <div class="title-box">智慧比价</div>
        <div class="comparison-box">
          <div class="comparison comparison-1 ">系统内置多种比价算法</div>
          <div class="comparison comparison-2">多家供应商透明化比价</div>
          <div class="comparison comparison-3">智能推荐供应商</div>
          <div class="comparison comparison-4">供应商在线报价</div>
        </div>
      </div>
    </div>
    <div class="panel">
      <div class="box flex-1 box-5" style="margin-right: 4px;">
        <div class="title-box">供应商动态监管</div>
        <img :src="getImg('wave_line.png')" class="wave-line" />
        <div class="supervise-box">
          <div class="supervise">
            <div class="supervise-text supervise-text-top" style="padding-top: 15px;">准入标准</div>
            <img :src="getImg('ic_point_1.svg')" class="supervise-icon" />
          </div>
          <div class="supervise">
            <img :src="getImg('ic_point_2.svg')" class="supervise-icon" />
            <div class="supervise-text supervise-text-bottom">履约情况</div>
          </div>
          <div class="supervise">
            <div class="supervise-text supervise-text-top">改进与淘<br/>汰机制</div>
            <img :src="getImg('ic_point_3.svg')" class="supervise-icon" />
          </div>
          <div class="supervise">
            <img :src="getImg('ic_point_4.svg')" class="supervise-icon" />
            <div class="supervise-text supervise-text-bottom">风险识别<br/>与管理</div>
          </div>
          <div class="supervise">
            <div class="supervise-text supervise-text-top">信息更新<br/>维护</div>
            <img :src="getImg('ic_point_5.svg')" class="supervise-icon" />
          </div>
          <div class="supervise">
            <img :src="getImg('ic_point_6.svg')" class="supervise-icon" />
            <div class="supervise-text supervise-text-bottom">评价</div>
          </div>
        </div>
      </div>
      <div class="box flex-1 box-6" style="margin-left: 4px;">
        <div class="title-box">定制化配置</div>
        <div class="customized">
          <img :src="getImg('bg_5.png')" class="pic">
          <div class="line-box">
            <div v-for="(item, index) in customizedList" :key="'customized-'+index" class="line">
              <img :src="getImg('ic_select.svg')" class="icon">
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel">
      <div class="box flex box-7">
        <div class="title">协同流程</div>
        <img :src="getImg('bg_arrow.svg')" class="bg-arrow" />
        <div class="coordination-box">
          <div class="coordination-type" style="left: -3px;">商机<br/>推荐</div>
          <div class="coordination-type" style="left: 55px;">平台<br/>询单</div>
          <div class="coordination-start">协同流程</div>
          <div class="coordination-step-box">
            <div v-for="(step, index) in stepList" :key="'step-'+index" class="coordination-step">
              <div class="coordination-num">{{ step.num }}</div>
              <img :src="getImg('ic_step.svg')" class="coordination-point" />
              <div class="coordination-title">{{ step.title }}</div>
              <div class="coordination-desc">{{ step.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'JOIN_SUPPLY_DEMAND',
  data () {
    return {
      customizedList: [
        '功能模块选择', '工作流配置', '数据展示和筛选', '权限控制', '快速响应', '用户自定义需求',
      ],
      stepList: [
        { num: '01', title: '协同订阅', desc: '供需双方进行协同订阅操作' },
        { num: '02', title: '报价比价', desc: '需求方选择供应商' },
        { num: '03', title: '合同签订', desc: '供需双方进行合同签订' },
        { num: '04', title: '执行履约', desc: '按照合同签订范围进行履约操作' },
        { num: '05', title: '交付验收', desc: '履约完成后，需求方进行验收' },
        { num: '06', title: '对账结算', desc: '交易双方对相关账目进行核对和结算' },
      ],
    };
  },
  methods: {
    getImg (name) {
      return require(`@/assets/imgs/supplyDemand/${name}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.supplyDemand-join {
  .panel {
    display: flex;
    .box {
      // height: 260px;
      background: linear-gradient(136deg, #D1E6FF 0%, #FFFFFF 17%, #FFFFFF 67%, #F2F8FF 100%);
      border-radius: 8px;
      margin-bottom: 8px;
      .title-box {
        padding: 15px;
        font-weight: bold;
        font-size: 18px;
        color: #1F2733;
        display: inline-block;
        position: relative;
        z-index: 1;
      }
      .title-box::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        bottom: 15px;
        left: 15px;
        background: linear-gradient(90deg, #237FFA 0%, rgba(190, 228, 255, 0) calc(100% - 45px));
        opacity: 0.58;
        width: 100%;
        height: 10px;
        z-index: -1;
      }
      .line-box {
        .line {
          font-weight: 400;
          font-size: 14px;
          color: #929AA6;
          padding-bottom: 8px;
          display: flex;
          align-items: center;
          .icon {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }
      }
    }
    .flex {
      flex: 1;
    }
    .flex-1 {
      flex: 1;
    }
    .flex-2 {
      flex: 2;
    }
    .box-1 {
      position: relative;
      .bg {
        width: 230px;
        height: 215px;
        position: absolute;
        top: 0;
        right: 0;
      }
      .row-1 {
        padding: 20px;
        display: flex;
        flex-direction: column;
        .theme {
          font-weight: bold;
          font-size: 16px;
          color: #1A66FF;
          .title {
            font-weight: 600;
            font-size: 28px;
            margin-right: 5px;
          }
        }
        .desc {
          font-weight: 400;
          font-size: 14px;
          color: #1F2733;
          padding: 15px 0;
          padding-right: 180px;
        }
      }
      .row-2 {
        padding: 20px 5px 45px 5px;
        display: flex;
        .advantage {
          flex: 1;
          margin: 0 5px;
          background: linear-gradient( 0deg, rgba(246,250,254,0.19) 0%, #E9F4FC 100%);
          border-radius: 8px;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          .advantage-title {
            width: 130px;
            text-align: center;
            padding: 5px 0;
            background: linear-gradient( 180deg, #237FFA 50%, #88bbff 100%);
            border-radius: 0px 0px 4px 4px;
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
          }
          .advantage-remark {
            text-align: center;
            padding-top: 25px;
            padding-bottom: 20px;
            font-weight: 400;
            font-size: 14px;
            color: #929AA6;
            .bold {
              color: #237FFA;
              font-size: 20px;
              padding: 0 5px;
            }
          }
        }
      }
    }
    .box-2 {
      min-width: 450px;
      position: relative;
      .bg, .audience-box {
        width: 290px;
        height: 290px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .audience-box {
        .topic {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: 600;
          font-size: 16px;
          color: #FFFFFF;
          text-shadow: 0px 2px 4px #0059D0;
        }
        .audience {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          .audience-title {
            font-weight: bold;
            font-size: 16px;
            color: #237FFA;
            padding-bottom: 8px;
          }
          .audience-remark {
            text-align: center;
            font-weight: 400;
            font-size: 12px;
            color: #1F2733;
          }
        }
        .audience-1 {
          width: 260px;
          height: 70px;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
        .audience-2 {
          width: 120px;
          height: 70px;
          bottom: 46px;
          left: -60px;
        }
        .audience-3 {
          width: 100px;
          height: 70px;
          bottom: 46px;
          right: -35px;
        }
      }
    }
    .box-3 {
      height: 240px;
      position: relative;
      overflow: hidden;
      .bg {
        width: 290px;
        height: 250px;
        position: absolute;
        right: 20px;
        bottom: -60px;
      }
      .intro {
        padding-left: 30px;
        padding-top: 5px;
      }
    }
    .box-4 {
      position: relative;
      overflow: hidden;
      .bg, .comparison-box {
        width: 265px;
        height: 170px;
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
      .comparison-box {
        .comparison {
          position: absolute;
          // background: linear-gradient( 180deg, #237FFA 0%, #66ABFC 43%, #BEE4FF 100%);
          background: linear-gradient( 180deg, #237FFA 50%, #88bbff 100%);
          border-radius: 0px 6px 0px 6px;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          font-weight: bold;
          font-size: 12px;
          color: #FFFFFF;
          padding: 5px 15px;
        }
        .comparison-1 {
          // width: 148px;
          // height: 26px;
          top: 20px;
          left: -40px;
        }
        .comparison-2 {
          // width: 148px;
          // height: 26px;
          top: 32px;
          right: -40px;
        }
        .comparison-3 {
          // width: 148px;
          // height: 26px;
          bottom: 30px;
          left: -40px;
        }
        .comparison-4 {
          // width: 148px;
          // height: 26px;
          bottom: 30px;
          right: -20px;
        }
      }
    }
    .box-5 {
      height: 240px;
      position: relative;
      .wave-line {
        width: 560px;
        height: 34px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .supervise-box {
        width: 560px;
        height: 120px;
        padding-left: 30px;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        .supervise {
          width: 86px;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          .supervise-text {
            width: 58px;
            height: 58px;
            font-weight: 400;
            font-size: 12px;
            color: #1F2733;
            text-align: center;
          }
          .supervise-text-top {
            padding-top: 5px;
          }
          .supervise-text-bottom {
            padding-top: 25px;
          }
          .supervise-icon {
            width: 58px;
            height: 58px;
          }
        }
      }
    }
    .box-6 {
      height: 240px;
      position: relative;
      .customized {
        padding: 0 20px;
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
        width: 550px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .pic {
          width: 310px;
          height: 178px;
        }
      }
    }
    .box-7 {
      height: 275px;
      position: relative;
      overflow: hidden;
      .title {
        padding: 15px;
        font-weight: bold;
        font-size: 18px;
        color: #1F2733;
        display: inline-block;
        position: relative;
        z-index: 1;
      }
      .bg-arrow, .coordination-box {
        width: 1144px;
        height: 118px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .coordination-box {
        .coordination-type {
          position: absolute;
          top: 6px;
          width: 68px;
          height: 68px;
          background: linear-gradient( 180deg, #237FFA 0%, #66ABFC 43%, #BEE4FF 100%);
          opacity: 0.9;
          font-weight: 600;
          font-size: 16px;
          color: #FFFFFF;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .coordination-start {
          position: absolute;
          bottom: 6px;
          left: 25px;
          font-weight: bold;
          font-size: 14px;
          color: #237FFA;
        }
        .coordination-step-box {
          position: absolute;
          left: 285px;
          top: -35px;
          height: 182px;
          display: flex;
          .coordination-step {
            height: 182px;
            width: 125px;
            border-left: 1px dashed #237FFA;
            position: relative;
            .coordination-num {
              position: absolute;
              top: 5px;
              left: -20px;
              background: #237FFA;
              border-radius: 9px;
              padding: 0 12px;
              font-size: 14px;
              color: #FFFFFF;
              font-weight: bold;
            }
            .coordination-point {
              position: absolute;
              top: 55px;
              left: -10.5px;
              width: 20px;
              height: 20px;
            }
            .coordination-title {
              position: absolute;
              top: 92px;
              left: 10px;
              font-weight: bold;
              font-size: 16px;
              color: #1F2733;
            }
            .coordination-desc {
              padding-right: 15px;
              position: absolute;
              top: 118px;
              left: 10px;
              font-size: 12px;
              color: #1F2733;
            }
          }
        }
      }
    }
  }
}
</style>
