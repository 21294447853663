import { render, staticRenderFns } from "./supplyDemand.vue?vue&type=template&id=2c1516aa&scoped=true"
import script from "./supplyDemand.vue?vue&type=script&lang=js"
export * from "./supplyDemand.vue?vue&type=script&lang=js"
import style0 from "./supplyDemand.vue?vue&type=style&index=0&id=2c1516aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c1516aa",
  null
  
)

export default component.exports